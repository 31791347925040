import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import ForgotPasswordForm from '../components/forgot-password-form'

import { get } from 'lodash-es'

export default ({ data }) => {
    let page = data.allWordpressPage.nodes[0]

    return (
        <Layout noFooter noSocialBar noScrollTop noHeader>
            <SEO
                title={get(page, 'yoast_meta.yoast_wpseo_title')}
                description={get(page, 'yoast_meta.yoast_wpseo_metadesc')}
            />
            <ForgotPasswordForm />
        </Layout>
    )
}

export const query = graphql`
    {
        allWordpressPage(filter: { slug: { eq: "forgot-password" } }) {
            nodes {
                yoast_meta {
                    yoast_wpseo_title
                    yoast_wpseo_metadesc
                }
            }
        }
    }
`
