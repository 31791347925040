import React, { forwardRef, useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './style.module.scss'
import { FaSpinner } from 'react-icons/fa'

const Errors = ({ statusCode, response, className }) => {
    if (!response) return null

    if (statusCode === 422 && response.errors) {
        return (
            <ul className={cn(className, styles.apiErrors)}>
                {response.errors.map(e => {
                    return <li key={e}>{e}</li>
                })}
            </ul>
        )
    }
    return null
}

export default forwardRef(
    (
        { onSubmit, loading, response, children, errorsClassName, statusCode },
        ref
    ) => {
        const [shouldScrollToEnd, setShouldScrollToEnd] = useState(false)
        useEffect(() => {
            if (statusCode === 422 && !loading) {
                setShouldScrollToEnd(true)
            } else {
                setShouldScrollToEnd(false)
            }
        }, [statusCode, loading])

        useEffect(() => {
            if (shouldScrollToEnd)
                window.scrollTo(0, document.body.scrollHeight)
        }, [shouldScrollToEnd])
        return (
            <form
                ref={ref}
                onSubmit={e => {
                    e.preventDefault()
                    onSubmit()
                }}
                className={cn(styles.form, {
                    [styles.disabledForm]: loading
                })}
            >
                {children}
                {loading && (
                    <div className={styles.spinnerContainer}>
                        <FaSpinner className={styles.spinner} />
                    </div>
                )}
                <Errors
                    response={response}
                    className={errorsClassName}
                    statusCode={statusCode}
                ></Errors>
            </form>
        )
    }
)
